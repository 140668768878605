import React, { useState } from 'react'
import { Link } from 'gatsby'

const HeaderLanding = () => {
  return (
    <div className="header-wrap">
      <div className="header">
        <Link className="logo-link" to="/">
          <div className="logo-wrap">
            <div className="logo-box"></div>
            <div className="logo">
              <h1>
                RICHARD<span>WEALE</span>
              </h1>
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default HeaderLanding
