import React from 'react'
import '../styles/styles.scss'
import Layout from '../components/LayoutLanding'

const success = () => {
  return (
    <Layout>
      <div className="success-wrap">
        <h1>
          SUCCESS! WELCOME, AND THANK YOU
          <br />
          FOR JOINING MY LAUNCH TEAM
        </h1>
        <h2>
          My current project and my next book launch is Master, the third book
          in the Ann Thrope series.
        </h2>
        <p>
          I have been working on a Superhero noir novel for some years. With the
          advent of the Ann Thrope books, and also the Polly Granger novel I am
          writing, it has gone on the back burner.
        </p>
        <p>
          I enclose the early chapters of Vigilante as a thank you for joining
          my Launch Team.
        </p>
        <p>Happy reading.</p>
        <p>Click on the link below to download your PDF of "Vigilante."</p>
        <a
          href="https://vigilantelaunchteamrw.s3-us-west-2.amazonaws.com/Vigilante+LT+Richard+Weale.pdf"
          target="_blank"
        >
          DOWNLOAD VIGILANTE
        </a>
      </div>
    </Layout>
  )
}

export default success
