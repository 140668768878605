import React from 'react'
import Header from './HeaderLanding'

const Layout = props => {
  return (
    <div className="layout">
      <Header />

      <div> {props.children}</div>
    </div>
  )
}

export default Layout
